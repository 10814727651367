<script setup lang="ts">
import type { VehicleImageType } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  pictures: VehicleImageType[]
}

const props = withDefaults(defineProps<Props>(), {
  pictures: () => [],
})

defineEmits<{
  close: []
}>()

const orderedPictures = computed(() => sortArrayByProps(props.pictures, 'vehicle_image_type', imageTypeOrder))
</script>

<template>
  <YscModal
    fullscreen
    @close="$emit('close')"
  >
    <div class="container">
      <div class="sm:columns-2 sm:gap-6">
        <div
          v-for="photo in orderedPictures"
          :key="photo.id"
          class="py-3"
        >
          <img
            :data-twic-src="`image:${twicpicsPath(photo.path)}`"
            alt="-"
          >
        </div>
      </div>
    </div>
  </YscModal>
</template>
