<script setup lang="ts">
import type { BookingRequestEmit, BookingRequestProps } from '#imports'

const props = withDefaults(defineProps<BookingRequestProps>(), {
  formId: 'booking-request-form',
  loading: false,
  bookingMinDays: 2,
  prices: null,
  weeklyDiscount: false,
  hasInstantBookingActivated: false,
  openDays: null,
  hasForcedDepartureMorning: false,
  hasForcedDepartureAfternoon: false,
  hasForcedArrivalMorning: false,
  hasForcedArrivalAfternoon: false,
  hasHalfDayActivated: false,
  is200kmFree: false,
  isUnlimitedKmActivated: false,
  isUnlimitedKmFree: false,
  weeklyFactor: null,
  isSelfInsured: false,
})

const emits = defineEmits<BookingRequestEmit & ((e: 'close') => void)>()

const {
  form,
  kilometersOptions,
  showWeeklyDiscountTip,
  hoursConstraintsForSelectedDate,
  onCalendarInput,
  onHourChange,
  onFormChange,
  onSubmit,
} = useBookingRequest({ props, emits })

const currentStep = ref('calendar')
const { getReferentialOptions } = useReferentialStore()

onMounted(() => {
  if (props.bookingRequestForm.dateRange?.start && props.bookingRequestForm.dateRange?.end) {
    currentStep.value = 'bookingForm'
  }
})
const onDateRangeClick = () => {
  currentStep.value = 'calendar'
}

const datePicker = ref()
const onClearDateButtonClick = () => {
  datePicker.value.clearRange()
}

watch(() => form.value.kilometers, (_newKm, oldKm) => {
  // No need to emit form change events on first value
  if (oldKm === null) {
    return
  }
  onFormChange()
})
</script>

<template>
  <YscModal
    is-header-left-on-mobile
    @close="$emit('close')"
  >
    <template #title>
      {{
        currentStep === 'calendar'
          ? $t('components.ho_booking_request_modal.your_travel_dates')
          : $t('components.ho_booking_request_modal.your_travel')
      }}
    </template>
    <YscForm
      :id="formId"
      class="relative"
    >
      <YscLoadingOverlay
        v-if="loading"
        :loading="loading"
        class="z-10"
      />

      <AppCamperBookingRequestWidgetCalendar
        v-show="currentStep === 'calendar'"
        ref="datePicker"
        :value="form.dateRange ?? undefined"
        show
        :availabilities="availabilities"
        :booking-min-days="bookingMinDays"
        :open-days="openDays"
        :has-forced-arrival-morning="hasForcedArrivalMorning"
        :has-forced-departure-afternoon="hasForcedDepartureAfternoon"
        :max-months-future-rental="maxMonthsFutureRental"
        is-expanded
        :show-clear-button="false"
        @input="onCalendarInput"
      />
      <div v-show="currentStep === 'bookingForm'">
        <div class="relative">
          <AppCamperBookingRequestWidgetDateTrigger
            ref="date-picker-trigger"
            :date-range="form.dateRange"
            :has-half-day-activated="hasHalfDayActivated"
            @click="onDateRangeClick"
          />
          <AppCamperBookingRequestWidgetHours
            v-if="hasHalfDayActivated && form.dateRange && form.dateRange.start && form.dateRange.end"
            :value="form.hours"
            :has-forced-arrival-morning="hoursConstraintsForSelectedDate.forceArrivalMorning"
            :has-forced-arrival-afternoon="hoursConstraintsForSelectedDate.forceArrivalAfternoon"
            :has-forced-departure-morning="hoursConstraintsForSelectedDate.forceDepartureMorning"
            :has-forced-departure-afternoon="hoursConstraintsForSelectedDate.forceDepartureAfternoon"
            :referential-hour-from-options="getReferentialOptions('hour_from')"
            :referential-hour-to-options="getReferentialOptions('hour_to')"
            @change="onHourChange"
          />
          <template v-if="showWeeklyDiscountTip">
            <AppCamperBookingRequestWidgetWeeklyDiscountTip
              :weekly-factor="weeklyFactor"
              class="my-3"
            />
            <YscDivider />
          </template>
        </div>
        <!-- START Kilometers -->
        <div
          v-if="props.prices"
          class="mt-3"
        >
          <template
            v-if="kilometersOptions.length > 1"
          >
            <span class="block font-semibold">{{ $t('data.booking.km_option.label') }}</span>
            <YscToggleGroup
              v-model.number="form.kilometers"
              class="mt-2 mb-6 text-sm"
              name="kilometers"
              grow
              :options="kilometersOptions"
              required
            />
          </template>
          <template v-else>
            <div class="flex justify-between items-center my-1 py-2 sm:py-3">
              <span class="block">
                {{ $t('data.booking.km_option.label') }}
              </span>
              <span class="block font-semibold">
                {{ kilometersOptions[0].text }}
              </span>
            </div>
          </template>
        </div>
        <!-- END Kilometers -->
        <template v-if="props.prices && form.kilometers !== undefined && form.kilometers !== null">
          <!-- START Price details -->
          <AppCamperBookingRequestWidgetPriceDetails
            :prices="props.prices"
            :kilometers="form.kilometers"
            :is-self-insured="props.isSelfInsured"
            :show-2x="!!props.prices.price_2x_first?.value"
          />
          <!-- END Price details -->
        </template>
      </div>
    </YscForm>
    <template #footer>
      <div
        v-if="currentStep === 'calendar'"
        class="flex flex-row-reverse justify-between"
      >
        <button
          class="btn btn-primary text-sm md:text-sm"
          :disabled="!(form.dateRange?.start && form.dateRange.end)"
          @click="currentStep = 'bookingForm'"
        >
          {{ $t('commons.actions.next') }}
        </button>
        <button
          v-if="form.dateRange?.start && form.dateRange.end"
          class="cursor-pointer text-sm text-gray-500 md:text-sm"
          @click="onClearDateButtonClick"
        >
          {{ $t('components.ho_booking_request_modal.clear_dates') }}
        </button>
      </div>
      <AppBookingRequestWidgetFooter
        v-else
        :form-id="formId"
        :cta-label="$t('commons.actions.continue')"
        is-modal
        @submit="onSubmit"
      />
    </template>
  </YscModal>
</template>
